
const categories = () => {
  let categories = null;

  return {
    getTemplateCategories: async () => {
			//TODO : Do we need this still now that we have categories?
			// Consider refactoring this away
			const mockResponse = await new Promise((resolve, reject) => {
				setTimeout(() => {
					resolve({
						data: {
							primaryKeys: [
								{
									id: 'listing_marketing',
									title: 'Listing Marketing',
									primaryKeys: ['Category:Listing Marketing'],
								},
								{
									id: 'agent_promotion',
									title: 'Agent Promotion',
									primaryKeys: ['Category:Agent Promotion'],
								},
								{
									id: 'testimonials',
									title: 'Testimonials',
									primaryKeys: ['Category:Testimonials'],
								},
								{
									id: 'market_updates',
									title: 'Market Updates',
									primaryKeys: ['Category:Market Updates'],
								},
								{
									id: 'holidays',
									title: 'Holidays',
									primaryKeys: ['Category:Holidays'],
								},
								{
									id: 'engaging_content',
									title: 'Engaging Content',
									primaryKeys: ['Category:Education','Category:Engagement','Category:Quotes'],
								},
								{
									id: 'brokerage_content',
									title: 'Brokerage Content',
									primaryKeys: ['Category:Brokerage Content'],
								}
							],
							secondaryKeys: [
								{
									id: 'all_formats',
									title: 'All Formats',
									secondaryKeys: null,
								},
								{
									id: 'landscape',
									title: 'Social Post - Landscape',
									secondaryKeys: ['Layout:Landscape','Format:Post'],
								},
								{
									id: 'portrait',
									title: 'Social Post - Portrait',
									secondaryKeys: ['Layout:Portrait','Format:Post'],
								},
								{
									id: 'square',
									title: 'Social Post - Square',
									secondaryKeys: ['Layout:Square','Format:Post'],
								},
								{
									id: 'flyer',
									title: 'Flyer',
									secondaryKeys: ['Layout:Portrait','Format:Flyer'],
								},
								{
									id: 'postcard',
									title: 'Postcard',
									secondaryKeys: ['Layout:Landscape','Format:Postcard'],
								},
							],
						},
					});
				},250);
			});

      return mockResponse;
    },
    getTemplateLayoutCategories: async () => {
			const mockResponse = await new Promise((resolve, reject) => {
				setTimeout(() => {
					resolve({
						data: [
							{
								id: 'all_formats',
								title: 'All Formats',
								secondaryKeys: null,
							},
							{
								id: 'landscape',
								title: 'Social Post - Landscape',
								secondaryKeys: ['Layout:Landscape','Format:Post'],
							},
							{
								id: 'portrait',
								title: 'Social Post - Portrait',
								secondaryKeys: ['Layout:Portrait','Format:Post'],
							},
							{
								id: 'square',
								title: 'Social Post - Square',
								secondaryKeys: ['Layout:Square','Format:Post'],
							},
							{
								id: 'flyer',
								title: 'Flyer',
								secondaryKeys: ['Layout:Portrait','Format:Flyer'],
							},
							{
								id: 'postcard',
								title: 'Postcard',
								secondaryKeys: ['Layout:Landscape','Format:Postcard'],
							},
						]
					});
				}, 250);
			});

      return mockResponse;
    },
		setTemplateCategories: (newCategories) => {
			categories = newCategories;
			return categories;
		}
  }
};

const singleton = categories();
Object.freeze(singleton);

export default singleton;
